// Here you can add other styles
.sidebar .nav-dropdown-toggle::before {
  background-image: url('../assets/img/icons/angle-right-solid.svg') !important;
}
.sidebar .nav-dropdown.open > .nav-dropdown-toggle::before {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.btn-green {
  background-color: #4dbd74;
  border-color: #4dbd74;
  color: #fff;
  &:hover {
    color: #fff;
  }
  &:focus {
    box-shadow: 0 0 0 0.2rem rgb(77, 189, 116);
  }
}

button.btn-ql-green {
  width: 2.5rem !important;
  background: #fd9800 !important;
  border-color: #fd9800 !important;
  color: #fff !important;
  &:hover {
    color: #fff !important;
  }
  &:focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 149, 0, 0.8) !important;
  }
}

.wrapper-icon-header-user {
  background-color: #2f353a;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.samsung-s10-device.editor,
.samsung-tab10-device-potrait.editor,
.samsung-tab10-device-landscape.editor {
  margin: 0;
  padding: 0;
}

// samsung s10 preview css
.samsung-s10-device {
  margin: 15% 0;
  padding: 0 7%;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}
.question-set-quill {
  width: 100% !important;
  height: 530px !important;
}

.samsung-s10-device > div > div > div > .ql-editor.preview {
  img {
    width: 100%;
    height: 100%;
  }
  iframe {
    width: 100%;
  }
}

// samsung tab 10 inch potrait preview css
.samsung-tab10-device-potrait {
  margin: 7% 0;
  padding: 0 7%;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.samsung-tab10-device-landscape {
  margin: 5% 0;
  padding: 0 5%;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.samsung-tab10-device-potrait > div > div > div > .ql-editor.preview,
.samsung-tab10-device-landscape > div > div > div > .ql-editor.preview {
  p,
  ul,
  ol,
  pre {
    font-size: 20px;
  }
  img {
    width: 60%;
    height: 60%;
    // max-width: 100%;
    // max-height: 100%;
  }
  iframe {
    width: 100%;
    height: 430px;
  }
}

.ql-editor {
  scroll-behavior: smooth;
}
.ql-editor.preview::-webkit-scrollbar {
  display: none;
}
.samsung-s10-device::-webkit-scrollbar,
.samsung-tab10-device-potrait::-webkit-scrollbar,
.samsung-tab10-device-landscape::-webkit-scrollbar {
  display: none;
}

.bazzle {
  position: relative;
  display: flex;
  justify-content: start;
  align-items: center;
  flex-direction: column;
}

// .bazzle {
//   border: 1px solid black;
//   padding: 30px;
//   border-radius: 15px;
//   background-color: #c8ccd2;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   flex-direction: column;
// }

.img-bazzle {
  width: 100%;
  height: 100%;
  position: absolute;
}

.wrapper-tab {
  margin-bottom: 15px;
}

.device-tab-btn-active {
  width: 10px;
  height: 10px;
  background-color: #000;
  border-radius: 10px;
  margin-left: 5px;
  margin-right: 5px;
  cursor: pointer;
  border: 1px solid black;
}
.device-tab-btn-inactive {
  width: 10px;
  height: 10px;
  border-radius: 10px;
  margin-left: 5px;
  margin-right: 5px;
  cursor: pointer;
  border: 1px solid black;
}

.main {
  margin-left: 250px;
  background-color: #fff !important;
}
.card {
  border: 0px !important;
}
@media (min-width: 992px) {
  .sidebar-fixed .sidebar {
    width: 250px !important;
  }
  html:not([dir='rtl']) .sidebar-lg-show.sidebar-fixed .main,
  html:not([dir='rtl']) .sidebar-lg-show.sidebar-fixed .app-footer,
  html:not([dir='rtl']) .sidebar-show.sidebar-fixed .main,
  html:not([dir='rtl']) .sidebar-show.sidebar-fixed .app-footer {
    margin-left: 250px;
  }
}
.sidebar .sidebar-nav {
  width: 250px !important;
}
.sidebar .nav {
  width: 250px !important;
}
.app-header .navbar-brand {
  width: 210px !important;
}
html:not([dir='rtl']) .sidebar {
  margin-left: -250px;
}
html:not([dir='rtl']) .main {
  margin-left: 0px;
}

.ql-editor.preview {
  .complex {
    border-radius: 0;
    border: 1px solid black;
    margin: 0.3rem;
    background: lightgray;
    span {
      visibility: hidden;
    }
  }
}
.ql-editor.preview.dnd {
  div p {
    background: #fff;
    border: 2px solid black;
    padding: 5px 10px;
  }
}
.image img {
  width: 100%;
}

.sidebar .nav-link.active {
  background: orange;
}

.btn-hidden {
  background-color: red;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}
.breadcrumb {
  margin: 0 !important;
}
.card {
  margin: 0 !important;
}

.remove-image-btn {
  width: 15px;
  height: 15px;
  background: red;
  position: absolute;
  left: 8px;
  top: -7px;
  border-radius: 100%;
  cursor: pointer;
  color: #fff;
  font-size: 9px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.full-width-item-modal {
  img {
    width: 100%;
  }
}

.fixed-btn {
  position: fixed;
  z-index: 2;
  right: 0;
}

.disabled-form-question {
  background-color: #ffffff9c;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
}

.wrapper-tooltip > p > span > img {
  width: 100%;
  height: auto;
}
.wrapper-tooltip > p > img {
  width: 100%;
  height: auto;
}

.red-close button {
  color: #fff;
  background-color: red !important;
  padding: 1.2rem 1.1rem !important;
}

.react-bs-table table td,
.react-bs-table table th {
  white-space: pre-wrap !important;
}

.margin-question-set {
  margin-bottom: 12px !important;
}

/* fill the blank css */
.fill {
  background: #ffff00;
  padding: 4px 2px;
  outline-offset: -2px;
  line-height: 1em;
  margin: 0 1px;
  border: 1px solid lightgray;
}

.fill::selection {
  display: none;
}

figure {
  width: unset !important;
}

.ck-editor__editable_inline {
  min-height: 150px;
}

.swal-wide {
  width: 677px !important;
}

.loading-component {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fixed-btn-step-next {
  position: fixed;
  bottom: 50px;
  right: 20px;
}

.fixed-btn-step-prev {
  position: fixed;
  bottom: 50px;
  // left: 50px;
}

.app-body {
  overflow: hidden;
}

a:focus {
  outline: unset !important;
}

.btn-info.override {
  background-color: #fff;
  border: 1px solid #c8ced3;
  color: #20a8d8;
}
.total-count {
  font-size: 35px;
}
.react-datepicker-wrapper {
  width: 100%;
  .react-datepicker__input-container {
    width: 100%;
    input {
      width: 100%;
      height: 35px;
      border: 1px solid lightgray;
    }
  }
}

.table-card {
  width: 100%;
  th, td {
    text-align: left;
    padding: 1px;
  }
}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

#ckeditor-passage .ck-editor__main {
  height: 720px;
  overflow-y: scroll;
  border: 1px solid rgba(0,0,0,.5);
}

.ck-rounded-corners {
  height: 100% !important;
}

.ck-editor__main {
  height: calc(100% - 40px);
}

.ck-toolbar__grouped-dropdown .ck-dropdown__panel.ck-dropdown__panel_se {
  left: auto !important;
  right: 0 !important;
}

.ck.ck-toolbar-dropdown .ck.ck-toolbar .ck.ck-toolbar__items {
  flex-wrap: wrap !important;
}

// .ck-dropdown,
// .ck.ck-dropdown .ck-dropdown__panel.ck-dropdown__panel_se {
//   z-index: 100000 !important;
// }

.player-wrapper {
  width: auto; // Reset width
  height: auto; // Reset height
}
.react-player {
  padding-top: 56.25%; // Percentage ratio for 16:9
  position: relative; // Set to relative
}

.react-player > div {
  position: absolute; // Scaling will occur since parent is relative now
}

.ck-dropdown__panel {
  position: absolute;
  z-index: 100000;
}
