// If you want to override variables do it here
@import 'variables';

// Import styles with default layout.
// If you are going to use dark layout please comment next line
@import '~@coreui/coreui-pro/scss/coreui.scss';

// Import styles with dark layout
// If you want to use dark layout uncomment next line
//@import "~@coreui/coreui-pro/scss/themes/dark/coreui-dark.scss";

// Temp fix for reactstrap
@import '~@coreui/coreui-pro/scss/_dropdown-menu-right.scss';

// If you want to add something do it here
@import 'custom';

// ie fixes
@import 'ie-fix';

// temp fixes
@import 'fixes';

// step zilla
@import 'step-zilla';

// custom quil
.custom-quil {
  .ql-editor {
    min-height: 8em;
  }
}

// #levelStat {
//   display: none;
// }

#levelStat {
  display: none;
}

.arrow-up-box {
  position: relative;
  height: 3vh;
}

.arrow-up {
  display: none;
  position: absolute;
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-bottom: 25px solid #778ca3;
}

.arrow-up.show {
  display: block;
}

.card-grade,
.card-level {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  border-radius: 1vw;
  height: 15vh;
  padding: 10px;
  color: #ededed;
}

.card-grade {
  margin: 10px 0 0 10px;
  min-width: 10vw;
  background-color: #2d98da;
}

.card-grade--inner,
.card-level--inner {
  width: 100%;
}

.card-level-wrapper {
  background-color: #778ca3;
  display: none;
  justify-content: space-around;
  padding: 0 0 1vh 0;
  width: 100%;
  border-radius: 8px;
}

.card-level-wrapper.show {
  display: flex;
}

.card-level {
  margin: 10px 0 0 0px;
  background-color: #ffffff;
  color: #778ca3;
  min-width: 12vw;
}

.card-grade-list{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  div{
    width: 100%;
  }
}

.sidebar{
  width: 250px !important;
}


.selectSR{
  display: flex;
}

.dropSelectSR{
  display: none;
  margin-right: 6px !important;
  text-align: -webkit-right !important;
  .dropdown{
    .nav-link{
      .wrapper-icon-school_region{
        background-color: #ffa500;
        width: max-content;
        height: 33px;
        color: white;
        border-radius: 5px;
        display: flex;
        font-weight: bold;
        padding: 10px;
        font-size: 21px;
        justify-content: center;
        align-items: center;
      }
      .wrapper-icon-school_region:hover{
        background-color: #ffa500a8;
      }
    }
    .dropdown-menu{
      position: absolute !important;
      flex-wrap: wrap;
      width: max-content;
    }
  }
}

.datepickerdsb{
  border-color: hsl(0,0%,80%) !important;
}

.datepickerdsb:focus{
  border-color: #2684ff !important;
  box-shadow: 0 0 0 0.7px #2986ff !important;
}

@media (max-width: 991.99px){
  .selectSR{
    display: none;
  }
  .dropSelectSR{
    display: flex !important;
    flex-direction: column !important;
    align-items: center;
    li{
      margin: 5px 5px 5px 5px !important;
    }
  }

}

@media (max-width: 463px){
  .navbar-nav{
    margin-top: -1px !important;
  }
  .daterangepicker{
    overflow: auto;
    height: 40vh;
  }
}

@media (max-width: 428px){
  .navbar-nav{
    margin-top: -3px !important;
  }
  .daterangepicker{
    overflow: auto;
    height: 40vh;
  }
}

.headerBreadcrumb{
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #c8ced3;
  flex-wrap: wrap;
  ol, .breadcrumb{
    border-bottom: 0 !important;
  }
}

.clearDate{
  position: absolute;
  z-index: 40;
  height: -webkit-fill-available;
  width: -webkit-fill-available;
  text-align: end;
  color: #c7c6c6;
  font-weight: 500;
  font-size: 23px;
  font-family: cursive;
  padding-right: 45px;
  top: -3px;
  cursor: pointer;
  margin-left: 76%;
}

.cancelButtonDate{
  display: none;
}

// Spinkit
$spinkit-spinner-color: $body-color;
@import '~spinkit/scss/spinkit.scss';
